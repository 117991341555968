import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

    constructor(
        private http: HttpClient) {}

    getById = (id: number) => {
        return this.http.get<any>(environment.url + 'usuario/' + id);
    }

    getUsuariosList = (tenantId: string, query, page, limit) => {
        if (!(query && query.length > 0)) query = '%20';
        return this.http.get<any>(environment.url + tenantId + '/usuarios' + '/' + query + '/' + page + '/' + limit);
    }

    getUsuariosFilterList = (tenantId: string, query, page, limit) => {
        if (!(query && query.length > 0)) query = '%20';
        return this.http.get<any>(environment.url + tenantId + '/usuarios-filter' + '/' + query + '/' + page + '/' + limit);
    }

    saveUsuario = (data: any) => {
        //Este no se esta usando, por el momento
        return this.http.put<any>(environment.url + 'moneda/', data);
    }

/*    deleteDes = (id: number) => {
        return this.http.delete<any>(environment.url + 'moneda/' + id);
    }*/

    deleteUsuario = (tenantId: string, id: number) => {
        //console.log("id", id);
        return this.http.delete<any>(environment.url + tenantId + '/usuario/' + id);
    }
}
